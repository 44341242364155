import { ThemeProvider } from '@mui/material';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import CustomTheme from './config/Themes/CustomTheme';
import ScrollToTop from './components/ScrollToTop';
import { useLayoutEffect, lazy, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './routes/ProtectedRoute';
import { AuthContextProvider } from './context/AuthContext';
import FallBackUI from './components/FallBackUI';
import { Web3ModalProvider } from './config/Web3ModalProvider';

const LazyLogin = lazy(() => import('./pages/Auth/Login'));
const LazyRouteError = lazy(() => import('./routes/RouteError'));

const LazyHome = lazy(() => import('./pages/Home'));
const LazyWallet = lazy(() => import('./pages/Wallet'));
const LazyAccount = lazy(() => import('./pages/Profile/Profile'));
const LazyMyStakes = lazy(() => import('./pages/Bets'));
const LazyCockPickHistory = lazy(() => import('./pages/CockPickHistory'));
const LazyCockPick = lazy(() => import('./pages/CockPickPage'));
const LazyTopUp = lazy(() => import('./pages/TopUp'));
const LazyWidraw = lazy(() => import('./pages/Widraw'));
const LazySignup = lazy(() => import('./pages/Auth/SignUp'));
const LazySignup1 = lazy(() => import('./pages/Auth/SignUp1'));
const ReferSignup = lazy(() => import('./pages/Auth/ReferalSignup'));

const LazyForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'));
const LazyHelp = lazy(() => import('./pages/Help'));
const LazyMyReferal = lazy(() => import('./pages/MyReferal'));
const LazyLanding = lazy(() => import('./pages/Landing'));


function App() {
  const scrollX = window.scrollX;

  const scrollY = window.scrollY;

  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });

  return (
    <Web3ModalProvider>
      <AuthContextProvider>
        <ThemeProvider theme={CustomTheme}>
          <Router>
            <ScrollToTop />
            <Suspense fallback={<FallBackUI />}>
              <Routes>
                <Route
                  path='/Home'
                  element={
                    <ProtectedRoute>
                      <LazyHome />
                    </ProtectedRoute>
                  }
                />
                {/* <Route
                path='/Marketplace'
                element={
                  <ProtectedRoute>
                    <Layout>
                      <Marketplace />
                    </Layout>
                  </ProtectedRoute>
                }
              /> */}
                <Route
                  path='/Wallet'
                  element={
                    <ProtectedRoute>
                      <LazyWallet />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path='/TopUp'
                  element={
                    <ProtectedRoute>
                      <LazyTopUp />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/accounts'
                  element={
                    <ProtectedRoute>
                      <LazyAccount />
                    </ProtectedRoute>
                  }
                />
                    
                <Route
                  path='/Bets'
                  element={
                    <ProtectedRoute>
                      <LazyMyStakes />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path='/CockpickHistory'
                  element={
                    <ProtectedRoute>
                      <LazyCockPickHistory />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path='/Cockpick'
                  element={
                    <ProtectedRoute>
                      <LazyCockPick />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path='/Withdraw'
                  element={
                    <ProtectedRoute>
                      <LazyWidraw />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/MyReferal'
                  element={
                    <ProtectedRoute>
                      <LazyMyReferal />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/Help'
                  element={
                    <ProtectedRoute>
                      <LazyHelp />
                    </ProtectedRoute>
                  }
                />
                 <Route
                  path='/MyReferal'
                  element={
                    <ProtectedRoute>
                      <LazyMyReferal />
                    </ProtectedRoute>
                  }
                />
            
                <Route path='/operator/:affiliateId' element={<LazySignup />} />
                <Route path='/Auth/SignUp' element={<LazySignup1 />} />
                <Route path='/refer/:id' element={<ReferSignup />} />


                <Route path='/Auth/ForgotPassword' element={<LazyForgotPassword />} />

                <Route path='/Auth/Login' element={<LazyLogin />} />
                <Route path='/' element={<LazyLanding />} />

                <Route path='*' element={<LazyRouteError />} />
              </Routes>
            </Suspense>
          </Router>
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ThemeProvider>
      </AuthContextProvider>
    </Web3ModalProvider>
  );
}

export default App;
