import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import classes from './Layout.module.sass';
import useMediaQuery from '@mui/material/useMediaQuery';
// import { useAppSelector } from '../hooks/ReduxHook';
import ConnectButton from '../components/ConnectButton';
import Help from '../assets/Images/help2.png';
import { useNavigate } from 'react-router-dom';

const NavBar = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const GoToHelp = () => {
    navigate('/Help');
  };
  return (
    <>
      {isMobile ? (
        <>
          {/* <div className={classes.usd}>
            <div className={classes.cusd}>USD</div>
            <div className={classes.busd}>
              {userBalance.loading ? '--' : Number(userBalance.value).toFixed(2)}
            </div>
          </div> */}
          <Box>
            <img src={Help} className={classes.img1} onClick={() => GoToHelp()} />
          </Box>
          <Box className={classes.navBar}>
            <ConnectButton />
          </Box>
        </>
      ) : (
        <>
          <Box>
            <img src={Help} className={classes.img1} onClick={() => GoToHelp()} />
          </Box>
          <Box className={classes.navBar}>
            {/* <NavLink
            to='/Home'
            className={(navData) => (navData.isActive ? `${classes.active}` : `${classes.link}`)}
          >
            Home
          </NavLink>
          <NavLink
            to='/Marketplace'
            className={(navData) => (navData.isActive ? `${classes.active}` : `${classes.link}`)}
          >
            Marketplace
          </NavLink> */}
            {/* <Typography component='div'>Home</Typography>
          <Typography component='div'>Marketplace</Typography> */}
            {/* <Typography component='div'>Collection</Typography>
          <Typography component='div'>Trade</Typography> */}
            {/* <Divider
            orientation='vertical'
            flexItem={true}
            sx={{ border: '1px solid rgba(255,255,255,0.25)' }}
          /> */}
            {/* <Avatar
            src={avatar}
            sx={{ width: '40px', height: '40px', border: `3px solid ${greenColor}` }}
          />
          <Typography component='div' sx={{ fontWeight: '500' }}>
            Maximus
          </Typography>*/}
            {/* <Typography
            component='div'
            onClick={() => {
              logOut();
            }}
          >
            Sign-out
          </Typography> */}
            {/* <ConnectWallet /> */}
            <ConnectButton />
          </Box>
        </>
      )}
    </>
  );
};

export default NavBar
