import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import { auth } from '../config/FirebaseConfig';

const AuthContext = createContext<any>({});

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<object | null>(null);
  const [tfa, setTFA] = useState<boolean>(true);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!tfa) return setLoading(false);

    const unsubscribe = onAuthStateChanged(auth, (user: any) => {
      // firebase.auth().currentUser?.getIdToken(true);
      if (user) {
        setUser({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          accessToken: user.accessToken,
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [tfa]);

  const signUp = (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const logIn = (email: string, password: string) => {
    return setPersistence(auth, browserSessionPersistence).then(() => {
      return signInWithEmailAndPassword(auth, email, password);
    });
  };

  const logOut = async () => {
    setUser(null);
    await signOut(auth);
  };

  const forgotPassword = (email: string) => {
    return sendPasswordResetEmail(auth, email);
  };
  const handleTFA = (value: boolean) => {
    setTFA(value);
  };
  return (
    <AuthContext.Provider value={{ user, logIn, signUp, logOut, forgotPassword, tfa, handleTFA }}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
