import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import classes from './Layout.module.sass';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Divider, ListItemAvatar, ListItemIcon, Theme } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Walleticon from '../../src/assets/Images/wallet.png';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchangeRounded';
import { useAuth } from '../context/AuthContext';
import logo from '../assets/Images/fightnightlogo.svg';
import MoneyIcon from '@mui/icons-material/Money';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { LuWallet } from 'react-icons/lu';
import { VscSettingsGear } from 'react-icons/vsc';
import { TbUsersPlus } from 'react-icons/tb';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';


const DrawerContent = () => {
  const navigate = useNavigate();
  const { logOut } = useAuth();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const GoToHome = () => {
    navigate('/Home');
  };

  // const GoToMarketplace = () => {
  //   navigate('/Marketplace');
  // };

  const GoToWallet = () => {
    navigate('/Wallet');
  };

  const GoToTopUp = () => {
    navigate('/TopUp');
  };
  const GoToWithdraw = () => {
    navigate('/Withdraw');
  };
  // const GoToReward = () => {
  //   navigate('/Reward');
  // };
  const GoToAccounts = () => {
    navigate('/accounts');
  };
  // const GoToReferal = () => {
  //   navigate('/MyReferal');
  // };
  const GoToHelp = () => {
    navigate('/Help');
  };
  const GoToHowItWorks = () => {
    navigate('/HowItWorks');
  };
  const GoToBetsTable = () => {
    navigate('/Bets');
  };

  const GoToCockPickHistory = () => {
    navigate('/CockpickHistory');
  };

  const GoToCockPickPage = () => {
    navigate('/Cockpick');
  };
  const GoToReferal = () => {
    navigate('/MyReferal');
  };
  return (
    <div>
      <Box className={classes.drawer}>
        <List className={classes.navList}>
          {/* {isMobile ? ( */}
          <>
            <img
              src={logo}
              className={classes.navlogoimage}
              width='160px'
              onClick={() => {
                navigate('/Home');
              }}
            />
            <Divider />
            {/* <ListItem button onClick={() => GoToHome()}>
                <ListItemIcon>
                  <HomeOutlinedIcon sx={{ color: 'white', fontSize: '30px' }} />
                </ListItemIcon>
                <ListItemText primary='HOME' className={classes.listText} />
              </ListItem>

              <ListItem button onClick={() => GoToMarketplace()}>
                <ListItemIcon>
                  <StorefrontOutlinedIcon sx={{ color: 'white', fontSize: '30px' }} />
                </ListItemIcon>
                <ListItemText primary='MARKETPLACE' className={classes.listText} />
              </ListItem> */}
          </>
          {/* ) : null} */}
          {/* <ListItem button sx={{ marginTop: { lg: '80px', xs: '0px' } }}>
            <Box
              component='img'
              sx={{
                height: '30px',
                width: '30px',
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt='The house from the offer.'
              src={Bellicon}
            />
            <ListItemText primary='NOTIFICATION' className={classes.listText} />
          </ListItem> */}
          <ListItem button onClick={() => GoToHome()} sx={{ marginTop: { xs: '0px' } }}>
            <ListItemIcon>
              <HomeOutlinedIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary='HOME' className={classes.listText} />
          </ListItem>

          {isMobile ? (
            <ListItem button onClick={() => GoToCockPickPage()}>
              <ListItemIcon>
                <MoneyIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary='LAST 2 DIGIT' className={classes.listText} />
            </ListItem>
          ) : null}

          <ListItem button onClick={() => GoToWallet()}>
            {/* <ListItemAvatar>
              <Box
                component='img'
                sx={{
                  height: '26px',
                  width: '26px',
                  color: 'black'
                }}
                alt='wallet icon'
                src={Walleticon}
              />
            </ListItemAvatar> */}
            <ListItemIcon>
              <LuWallet className={classes.icon1} />
            </ListItemIcon>
            <ListItemText primary='WALLET' className={classes.listText} />
          </ListItem>

          <ListItem button onClick={() => GoToTopUp()}>
            <ListItemIcon>
              <CurrencyExchangeIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary='DEPOSIT' className={classes.listText} />
          </ListItem>
          <ListItem button onClick={() => GoToWithdraw()}>
            <ListItemIcon>
              <SavingsOutlinedIcon  sx={{ color: 'white', fontSize: '30px' }} />
            </ListItemIcon>
            <ListItemText primary='WITHDRAWAL' className={classes.listText} />
          </ListItem>
          {/* 
          <ListItem  button onClick={() => GoToReward()}>
            <ListItemIcon>
              <EmojiEventsIcon sx={{ color: 'white', fontSize: '30px' }} />
            </ListItemIcon>
            <ListItemText primary='CASH OUT' className={classes.listText} />
          </ListItem> */}

          <ListItem button onClick={() => GoToBetsTable()}>
            <ListItemIcon>
              <MonetizationOnRoundedIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary='STAKING HISTORY' className={classes.listText} />
          </ListItem>

          <ListItem button onClick={() => GoToCockPickHistory()}>
            <ListItemIcon>
              <HistoryRoundedIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary='L2D HISTORY' className={classes.listText} />
          </ListItem>

          <ListItem button onClick={() => GoToAccounts()}>
            <ListItemIcon>
              <SwitchAccountIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary='PROFILE' className={classes.listText} />
          </ListItem>
      
          <ListItem button onClick={() => GoToReferal()}>
            <ListItemIcon>
              <TbUsersPlus className={classes.icon1} />
            </ListItemIcon>
            <ListItemText primary='MY REFERRALS' className={classes.listText} />
          </ListItem>

          <ListItem button onClick={() => GoToHelp()}>
            <ListItemIcon>
              <HelpOutlineIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary='HELP' className={classes.listText} />
          </ListItem>
     
          <ListItem button onClick={() => logOut()}>
            <ListItemIcon>
              <ExitToAppIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary='SIGN OUT' className={classes.listText} />
          </ListItem>

          {/* <ListItem button>
            <Box
              component='img'
              sx={{
                height: '30px',
                width: '30px',
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt='The house from the offer.'
              src={Messageicon}
            />
            <ListItemText primary='MESSAGE' className={classes.listText} />
          </ListItem> */}

          {/* <ListItem button>
            <Box
              component='img'
              sx={{
                height: '30px',
                width: '30px',
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt='The house from the offer.'
              src={Networkicon}
            />
            <ListItemText primary='GLOBE' className={classes.listText} />
          </ListItem> */}

          {/* <ListItem button sx={{ mt: 3 }}>
            <Box
              component='img'
              sx={{
                height: '30px',
                width: '30px',
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt='The house from the offer.'
              src={Settingsicon}
            />
            <ListItemText primary='SETTINGS' className={classes.listText} />
          </ListItem> */}
        </List>
      </Box>
    </div>
  );
};

export default DrawerContent;
