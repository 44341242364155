import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import Layout from '../layout';

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/Auth/Login');
    }
  }, [navigate, user]);
  return <>{user ? <Layout>{children}</Layout> : null}</>;
};

export default PrivateRoute;
